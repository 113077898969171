import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import './DashboardWindow.scss';
import { api } from '../../utils/fetchAPI';
import { Subscription } from '../../types/Subscription';
import { Loader } from '../Loader';
import { ErrorMessage } from '../ErrorMessage';
import { InformationMessage } from '../InformationMessage';
import { AddSeatsModalOne } from './AddSeatsModalOne';
import { AddSeatsModalTwo } from './AddSeatsModalTwo';
import { SelectedSubscription } from '../../types/SelectedSubscription';
import { SubscriptionCard } from './SubscriptionCard';
import { DashboardHeader } from './DashboardHeader';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getSubscriptionsAsync, setErrorMessage } from '../../features/subscriptions/subscriptionsSlice';
import { MonthlyCost } from '../../types/MonthlyCost';

export const DashboardWindow: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    allSubscriptions,
    status: subscriptionsStatus,
    errorMessage,
  } = useAppSelector((state) => state.subscriptions);

  const [addSeatsStep, setAddSeatsStep] = useState<0 | 1 | 2>(0);
  const [isAddingSeats, setIsAddingSeats] = useState(false);
  const [isAddedSeats, setIsAddedSeats] = useState(false);
  const [isAddSeatsError, setIsAddSeatsError] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState<SelectedSubscription | null>(null);

  const [monthlyCosts, setMonthlyCosts] = useState<MonthlyCost[] | null>(null);

  const startPaidServiceOnApi = async (domain: string, id: string) => {
    try {
      await api.post('/subscriptions/start-paid-service', { domain, id });
      dispatch(getSubscriptionsAsync());
    } catch (e) {
      console.error(e);
    }
  };
  const onAddingLicenses = (sub: Subscription) => {
    setSelectedSubscription({
      id: sub.subscriptionId,
      domain: sub.customerDomain,
      cost: sub.cost.clientCost || 6,
      discountPercent: sub.cost.discountPercent || 10,
      newSeats: 0,
      currentSeats: sub.seats.numberOfSeats,
      plan: sub.skuName,
      startDate: new Date(Number(sub.plan.commitmentInterval?.startTime) || 0),
      endDate: new Date(Number(sub.plan.commitmentInterval?.endTime) || 0),
      currency: sub.cost.currency,
    });

    setAddSeatsStep(1);
  };

  const addLicencesOnApi = async () => {
    if (!selectedSubscription || isAddingSeats) {
      return;
    }

    setIsAddingSeats(true);

    try {
      const { domain, newSeats, id } = selectedSubscription;
      await api.post('/subscriptions/add-seats', { domain, newSeats, id });

      setSelectedSubscription(null);
      setAddSeatsStep(0);

      setIsAddedSeats(true);
      setTimeout(() => setIsAddedSeats(false), 3000);
    } catch (e) {
      console.log(e);

      setIsAddSeatsError(true);
      setTimeout(() => setIsAddSeatsError(false), 3000);
    }

    setIsAddingSeats(false);
  };

  const getMyMontlyCostsFromApi = async () => {
    try {
      const costs = await api.get<MonthlyCost[]>('/monthly-costs');
      setMonthlyCosts(costs);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!allSubscriptions) {
      dispatch(getSubscriptionsAsync());
    }

    getMyMontlyCostsFromApi();
  }, []);

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => dispatch(setErrorMessage(null)), 8000);
    }
  }, [errorMessage]);

  const setSubscription = (subscriptions: SelectedSubscription | null) => {
    setSelectedSubscription(subscriptions);
  };
  const setStep = (step: 0 | 1 | 2) => {
    setAddSeatsStep(step);
  };

  return (
    <div className="dashboard-window pos-rel">
      {isAddedSeats && <InformationMessage message={'Licenses successfully added!'} />}

      {isAddSeatsError && <ErrorMessage text={'Unexpected error on adding licenses!'} />}

      {subscriptionsStatus === 'failed' && errorMessage && <ErrorMessage text={errorMessage} />}

      {addSeatsStep === 1 && selectedSubscription && (
        <AddSeatsModalOne subscription={selectedSubscription} setSubscription={setSubscription} setStep={setStep} />
      )}

      {addSeatsStep === 2 && selectedSubscription && (
        <AddSeatsModalTwo
          subscription={selectedSubscription}
          setStep={setStep}
          onConfirm={addLicencesOnApi}
          isLoading={isAddingSeats}
        />
      )}

      <DashboardHeader monthlyCosts={monthlyCosts} />

      <div className="dashboard-window__content">
        {subscriptionsStatus === 'loading' && <Loader />}

        {subscriptionsStatus === 'idle' &&
          allSubscriptions?.map((subscription) => (
            <SubscriptionCard
              subscription={subscription}
              onAddingLicenses={onAddingLicenses}
              key={subscription.subscriptionId}
            />
          ))}
      </div>
    </div>
  );
};
