import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIUser } from '../../types/User';
import { fetchUser } from './userAPI';

export interface UserState {
  user: APIUser | null;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: UserState = {
  user: null,
  status: 'idle',
};

export const getUserAsync = createAsyncThunk('user/fetchUser', async () => {
  return await fetchUser();
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<APIUser | null>) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUserAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.user = action.payload;
      })
      .addCase(getUserAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { setUser } = userSlice.actions;
