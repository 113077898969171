import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { config } from '../../config';
import partnerwayLogo from '../../media/logo.svg';
import { api } from '../../utils/fetchAPI';
import { Loader } from '../Loader';
import { APIUser } from '../../types/User';
import './SingInWindow.scss';

export const SingInWindow: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [isPassError, setIsPassError] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  async function signInToAPI() {
    setIsLoading(true);

    try {
      await api.post('/auth/sign-in', { email, password });

      const user = await api.get<APIUser>('/auth/me');

      if (!user.paymentType) {
        navigate('/transfer-token');
      } else {
        navigate('/dashboard');
      }
    } catch (e) {
      console.error(e);

      setIsPassError(true);
      setIsEmailError(true);
    }

    setIsLoading(false);
  }

  async function handleCredentialResponse(response: any) {
    const idToken = response.credential;

    await api.post('/auth/gsi', { idToken });

    const user = await api.get<APIUser>('/auth/me');

    if (!user.paymentType) {
      navigate('/transfer-token');
    } else {
      navigate('/dashboard');
    }
  }

  const formSubmit = () => {
    signInToAPI();
  };

  const loadScript = (src: string) =>
    new Promise((resolve, reject) => {
      if (document.querySelector(`script[src="${src}"]`)) {
        return resolve('');
      }

      const script = document.createElement('script');

      script.src = src;
      script.onload = () => resolve('');
      script.onerror = (err) => reject(err);
      document.body.appendChild(script);
    });

  useEffect(() => {
    const src = 'https://accounts.google.com/gsi/client';

    loadScript(src)
      .then(() => {
        // @ts-ignore
        const google = window.google;

        google.accounts.id.initialize({
          client_id: config.googleClientId,
          callback: handleCredentialResponse,
        });
        google.accounts.id.renderButton(document.getElementById('googleBTN'), {
          type: 'standard',
          theme: 'filled_blue',
          size: 'large',
          width: 400,
        });
        google.accounts.id.prompt();
      })
      .catch(console.error);

    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`);

      if (scriptTag) {
        document.body.removeChild(scriptTag);
      }
    };
  }, []);

  return (
    <div className="sing-in-window">
      {isLoading && (
        <div className="sing-in-window__modal">
          <Loader />
        </div>
      )}

      <img height="24" className="sing-in-window__logo" src={partnerwayLogo} alt="Partnerway Logo" />

      <span className="sing-in-window__title">Sign in</span>

      <div className="googleGSI-button" id="googleBTN"></div>

      <div className="sing-in-window__divider">or</div>

      <input
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
          setIsEmailError(false);
        }}
        onBlur={() => {
          if (!email.trim()) {
            setIsEmailError(true);
          }
        }}
        className={classNames('sing-in-window__input+ input mb-24', {
          'input--error': isEmailError,
        })}
        type="email"
        placeholder="Email address"
      />

      <input
        value={password}
        onChange={(e) => {
          setIsPassError(false);
          setPassword(e.target.value);
        }}
        onBlur={() => {
          if (!password.trim()) {
            setIsPassError(true);
          }
        }}
        className={classNames('sing-in-window__input+ input mb-40', {
          'input--error': isPassError,
        })}
        type={showPassword ? 'text' : 'password'}
        placeholder="Password"
      />

      <label className="sing-in-window__checkbox">
        <input onClick={() => setShowPassword(!showPassword)} type="checkbox" /> Show password
      </label>

      <div className="sing-in-window__links">
        <Link className="sing-in-window__create-btn" to="/sign-up">
          Сreate account
        </Link>

        <button onClick={formSubmit} className="sing-in-window__login-btn+ button button--primary">
          Log in
        </button>
      </div>
    </div>
  );
};
