import React, { ReactNode } from 'react';
import styles from './ModalWindow.module.scss';

type Props = {
  title: string;
  text: ReactNode;
  buttons: ReactNode;
};

export const ModalWindow: React.FC<Props> = ({ title, text, buttons }) => {
  return (
    <div className={styles.modalBase}>
      <div className={styles.modalWindow}>
        <div className={styles.title}>{title}</div>

        <div className={styles.content}>{text}</div>

        <div className={styles.buttonsGroup}>{buttons}</div>
      </div>
    </div>
  );
};
