import React from 'react';
import { SelectedSubscription } from '../../../types/SelectedSubscription';
import { ModalWindow } from '../../ModalWindow';

type Props = {
  subscription: SelectedSubscription;
  setStep: (step: 0 | 1 | 2) => void;
  onConfirm: () => void;
  isLoading: boolean;
};

export const AddSeatsModalTwo: React.FC<Props> = ({ subscription, onConfirm, setStep, isLoading }) => {
  const { endDate, cost, discountPercent, newSeats, currentSeats, startDate, plan } = subscription;

  const totalWithoutDiscount = cost * newSeats;
  const totalPriceWithDiscount = totalWithoutDiscount - totalWithoutDiscount * (discountPercent / 100);
  const totalPerMonthForAllSeats =
    cost * (newSeats + currentSeats) - cost * (newSeats + currentSeats) * (discountPercent / 100);

  const getDateInModal = () => {
    if (!subscription) {
      return;
    }
    return `${startDate.toDateString()} - ${endDate.toDateString()}`;
  };
  const getTotalInModal = () => {
    if (!subscription) {
      return;
    }
    const date1 = new Date();
    const date2 = endDate;
    let months = (date2.getFullYear() - date1.getFullYear()) * 12;

    months -= date1.getMonth();
    months += date2.getMonth();
    months = months <= 0 ? 1 : months;

    return (totalPriceWithDiscount * months).toFixed(2);
  };

  return (
    <ModalWindow
      title="Add seats"
      text={
        <div className="modal-seats">
          <div className="modal-seats__plan">{plan}</div>
          <br />
          <div className="modal-seats__header-body">
            <div className="modal-seats__header-numbers">
              <span className="modal-seats__header-text">{currentSeats}</span>
              <span className="modal-seats__header-text">{newSeats}</span>
              <span className="modal-seats__header-text--bolt">{+currentSeats + +newSeats}</span>
            </div>

            <div className="modal-seats__header-numbers">
              <span className="modal-seats__header-text">current licenses</span>
              <span className="modal-seats__header-text">additional licenses</span>
              <span className="modal-seats__header-text--bolt">total number of licenses</span>
            </div>
          </div>

          <div className="dashboard-window__divider"></div>

          <div className="modal-seats__value-title">Approximate value</div>

          <div className="modal-seats__info-body">
            <span className="modal-seats__info-text">
              <strong>€{totalPriceWithDiscount.toFixed(2)}</strong> monthly cost for additional licenses.
            </span>
            <span className="modal-seats__info-text">
              In total <strong>€{totalPerMonthForAllSeats.toFixed(2)}</strong> per month including additional licenses.
            </span>
            {
              <span className="modal-seats__info-text">
                Cost for additonal licenses for the remaining contract period ({getDateInModal()}) is{' '}
                <strong>€{getTotalInModal()}</strong>
              </span>
            }
            <span className="modal-seats__info-text">
              An additional prorated cost will be applied to the remainder of the current billing period.
            </span>
          </div>
        </div>
      }
      buttons={
        <>
          <button className="button button--secondary" onClick={() => setStep(1)}>
            Back
          </button>
          <button disabled={isLoading} className="button button--primary" onClick={() => onConfirm()}>
            {isLoading ? 'Wait please' : 'Accept'}
          </button>
        </>
      }
    />
  );
};
