import React, { useState } from 'react';
import { api } from '../../utils/fetchAPI';
import { ErrorMessage } from '../ErrorMessage';
import { InformationMessage } from '../InformationMessage';
import './SupportWindow.scss';

export const SupportWindow: React.FC = () => {
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const sendSupportMessage = async () => {
    try {
      await api.post('/support', { message });
      setMessage('');
      setIsSuccess(true);
      setTimeout(() => setIsSuccess(false), 3000);
    } catch (err) {
      console.error(err);
      setIsError(true);
      setTimeout(() => setIsError(false), 5000);
    }
  };

  return (
    <div className="support-window pos-rel">
      <h1 className="support-window__title+ page__title">Support</h1>

      {isError && <ErrorMessage text={'Unexpected error on sending message! Try again later'} />}

      {isSuccess && <InformationMessage message="Support message successfully arived!" />}

      <input
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Your message"
        className="support-window__input input"
        type="text"
      />

      <span className="support-window__sub-title input-sub-info">
        Indicate your problem and our manager will help you
      </span>

      <button className="support-window_send-button button button--primary" onClick={() => sendSupportMessage()}>
        Send
      </button>
    </div>
  );
};
