import React from 'react';
import './SignUp.scss';
import { SignUpWindow } from '../../components/SignUpWindow';

export const SignUp: React.FC = () => {
  return (
    <div className="sing-up-page">
      <SignUpWindow />
    </div>
  );
};
