import React from "react";
import './SignIn.scss';
import { SingInWindow } from "../../components/SingInWindow";

export const SignIn: React.FC = () => {
  return (
    <div className="sing-in-page">
      <SingInWindow />
    </div>
  );
};
