import React from 'react';
import s from './PageNotFound.module.css';
import notFoundImg from '../../media/notFound.svg';
import { Link } from 'react-router-dom';

export const PageNotFound: React.FC = () => {
  return (
    <div className={s.pageNotFound}>
      <div className={s.textGroup}>
        <span className={s.code}>
          <span className={s.textColorBlack}>404 </span>That’s an error.
        </span>
        <span className={s.text}>Sorry, page not found :(</span>
        <Link className="button button--primary" to={'/dashboard'}>
          Back
        </Link>
      </div>
      <img className={s.img} src={notFoundImg} alt="Robot Page not found" />
    </div>
  );
};
