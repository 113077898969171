import React from 'react';
import { SideBar } from '../../components/SideBar';
import s from './ThankYou.module.css';

export const ThankYou: React.FC = () => {
  return (
    <div className={s.page}>
      <SideBar isInDevelopment={true} />

      <div className={s.content}>
        <div className={s.thankYou}>
          <span className={s.bigText}>Glad to see you!</span>
          <span className={s.subText}>Thank you for registration, other content will be available later.</span>
        </div>
      </div>
    </div>
  );
};
