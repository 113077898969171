import React from "react";
import './HomePage.scss'
import { SideBar } from "../../components/SideBar";
import { Outlet } from 'react-router-dom';

export const HomePage: React.FC = () => {
  return (
    <div className="page">
      <SideBar />

      <div className="page__content">
        <Outlet />
      </div>
    </div>
  );
};
