import React, { ReactNode } from 'react';
import './TransferStep.scss';

type Props = {
  text: ReactNode | null;
  icon: any;
  img: any | null;
};

export const TransferStep: React.FC<Props> = ({ text, icon, img }) => (
  <div className="transfer-step">
    <img width="30" src={icon} alt="icon" className="transfer-step__icon" />

    {text && <div className="transfer-step__text">{text}</div>}

    {img && <img className="transfer-step__image" src={img} height="350" alt="step image" />}
  </div>
);
