import React from 'react';
import classNames from 'classnames';
import { Subscription } from '../../../types/Subscription';

type Props = {
  subscription: Subscription;
  onAddingLicenses: (subscription: Subscription) => void;
};

export const SubscriptionCard: React.FC<Props> = ({ subscription, onAddingLicenses }) => {
  const { subscriptionId, customerDomain, skuName, seats, status, cost, plan } = subscription;
  const { licensedNumberOfSeats, maximumNumberOfSeats, numberOfSeats } = seats;
  const { clientCost, discountPercent } = cost;

  const isFlexibleOrTrial = plan.planName === 'TRIAL' || plan.planName === 'FLEXIBLE';
  const maxNumOfSeatsForSubscription = isFlexibleOrTrial ? licensedNumberOfSeats : numberOfSeats;
  const availableMaxForSubscription = isFlexibleOrTrial ? maximumNumberOfSeats : numberOfSeats;
  const perscentOfLicenses = seats.licensedNumberOfSeats / (availableMaxForSubscription / 100);

  const costPerUserWithDiscount = clientCost - clientCost * (discountPercent / 100);
  const totalCostWithDiscount = costPerUserWithDiscount * maxNumOfSeatsForSubscription;

  const getCurrencySumbol = (cur: 'USD' | 'UAH' | 'EUR') => {
    const symbols = {
      USD: '$',
      UAH: '₴',
      EUR: '€',
    };

    return symbols[cur];
  };

  return (
    <div className="dashboard-window__card" key={subscriptionId}>
      <div className="dashboard-window__card-tags">
        <div
          className={classNames('status-tag', {
            'status-tag--success': status === 'ACTIVE',
            'status-tag--warning': status === 'in-progress',
            'status-tag--error': status === 'disconnected',
          })}
        >
          {status}
        </div>
      </div>

      <div className="dashboard-window__domain-product">{skuName}</div>

      <div className="dashboard-window__domain-name">{customerDomain}</div>

      <div className="dashboard-window__domain-product-type+ disp-none">{skuName}</div>

      <div className="dashboard-window__domain-licenses-box">
        <div className="dashboard-window__domain-licenses-label">Licenses</div>

        <div className="dashboard-window__domain-licenses-values">
          {licensedNumberOfSeats} / {availableMaxForSubscription}
        </div>
      </div>

      <div className="dashboard-window__domain-pregress-box">
        <progress className="dashboard-window__domain-pregress" id="file" max="100" value={perscentOfLicenses}>
          {perscentOfLicenses}
        </progress>
      </div>

      {subscription.trialSettings?.isInTrial ? (
        <button
          className="dashboard-window__card-add-seats"
          style={{
            cursor: isFlexibleOrTrial ? 'auto' : 'pointer',
            color: isFlexibleOrTrial ? 'transparent' : '',
          }}
        >
          Start paid service
        </button>
      ) : (
        <button
          style={{
            cursor: isFlexibleOrTrial ? 'auto' : 'pointer',
            color: isFlexibleOrTrial ? 'transparent' : '',
          }}
          className="dashboard-window__card-add-seats"
          onClick={() => {
            if (isFlexibleOrTrial || !plan.isCommitmentPlan) {
              return;
            }

            onAddingLicenses(subscription);
          }}
        >
          Add licenses
        </button>
      )}

      <div className="dashboard-window__divider"></div>

      <div className="dashboard-window__cost-body">
        <div className="dashboard-window__cost-info">
          <div className="dashboard-window__saved-title">
            {getCurrencySumbol(cost.currency)}
            {totalCostWithDiscount.toFixed(2)}/month
          </div>

          <div className="dashboard-window__saved-subtitle">
            ({getCurrencySumbol(cost.currency)}
            {costPerUserWithDiscount.toFixed(2)} per user per month |{' '}
            <span className="text-bolt-1">Save {discountPercent.toFixed(2)}%</span>)
          </div>
        </div>

        <div className="dashboard-window__cost-tag">
          {subscription.trialSettings?.isInTrial && <div className="status-tag status-tag--trial">Trial</div>}
        </div>
      </div>
    </div>
  );
};
