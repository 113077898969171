import React, { useState } from 'react';
import './App.scss';
import { Route, Routes } from 'react-router-dom';
import { HomePage } from './pages/HomePage';
import { SignIn } from './pages/SignIn';
import { Header } from './components/Header/Header';
import { YourDomainsWindow } from './components/YourDomainsWindow';
import { BillingWindow } from './components/BillingWindow';
import { SupportWindow } from './components/SupportWindow';
import { DashboardWindow } from './components/DashboardWindow';
import { InformationWindow } from './components/InformationWindow';
import { SignUp } from './pages/SignUp';
import { TransferTokenWindow } from './components/TransferTokenWindow';
import { ModalWindow } from './components/ModalWindow';
import { ThankYou } from './pages/ThankYou';
import { PageNotFound } from './pages/PageNotFound';
import { AcceptTeamInvite } from './pages/AcceptTeamInvite';
// import { HeaderLanding } from "./components/HeaderLanding";

function App() {
  const [modal, setModal] = useState(false);

  return (
    <div className="App">
      <div className="mobile-not">Sorry, mobile is not supported!</div>

      <header className="App-header">
        {/* <HeaderLanding /> */}
        <Header />
      </header>

      {modal && (
        <ModalWindow
          title="Change payment method?"
          text="Please note if you change your payment method to invoising, you will need to
contact Support to change back to credit or debit card and make sure there is a 
sufficient limit, taking into account growth in charges over time."
          buttons={
            <>
              <button onClick={() => setModal(false)} className="button button--primary">
                Close
              </button>
            </>
          }
        />
      )}

      <main className="main">
        <Routes>
          <Route path="/transfer-token" element={<TransferTokenWindow />} />

          <Route path="/thank-you" element={<ThankYou />} />

          <Route path="/" element={<HomePage />}>
            <Route index element={<BillingWindow />} />
            <Route path="billing" element={<BillingWindow />} />
            <Route path="dashboard" element={<DashboardWindow />} />
            <Route path="domains" element={<YourDomainsWindow />} />
            <Route path="support" element={<SupportWindow />} />
            <Route path="info" element={<InformationWindow />} />
          </Route>

          <Route path="/sign-in" element={<SignIn />} />

          <Route path="/invitation" element={<AcceptTeamInvite />} />

          <Route path="/sign-up" element={<SignUp />} />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </main>
    </div>
  );
}

export default App;
