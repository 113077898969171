import React from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import totalSaved from '../../../media/totalSaved.svg';
import totalSpent from '../../../media/totalSpent.svg';
import { MonthlyCost } from '../../../types/MonthlyCost';
import { formatCurrency } from '../../../utils/formatCurrency';

type Props = {
  monthlyCosts: MonthlyCost[] | null;
};

export const DashboardHeader: React.FC<Props> = ({ monthlyCosts }) => {
  const { user } = useAppSelector((state) => state.user);

  if (!user) {
    return null;
  }

  const totalSpentAmounts = { USD: 0, UAH: 0, EUR: 0 };
  const totalSavedAmounts = { USD: 0, UAH: 0, EUR: 0 };

  monthlyCosts?.forEach((cost) => {
    totalSpentAmounts[cost.totalCurrency] += cost.totalSpent;
    totalSavedAmounts[cost.totalCurrency] += cost.totalSaved;
  });

  const totalSpentAmount = totalSpentAmounts[user.currency];
  const totalSavedAmount = totalSavedAmounts[user.currency];

  return (
    <div className="dashboard-window__header">
      <div className="dashboard-window__title-block">
        <h1 className="dashboard-window__title page__title">Dashboard</h1>
        <div className="dashboard-window__header-buttons">
          <button className="button button--secondary">All time</button>

          <Link to={'/transfer-token'} className="button button--primary">
            + New domain
          </Link>
        </div>
      </div>
      <div className="dashboard-window__header-info">
        <div className="dashboard-window__info-block">
          <img src={totalSpent} alt="Total spent" width="50" height="50" />
          <div className="dashboard-window__info-content">
            <span className="dashboard-window__info-title">Total spend</span>

            <span className="dashboard-window__info-text">
              {formatCurrency(totalSpentAmount || 0, user.currency, true)}
            </span>
          </div>
        </div>

        <div className="dashboard-window__info-block">
          <img src={totalSaved} alt="Total saved" width="50" height="50" />
          <div className="dashboard-window__info-content">
            <span className="dashboard-window__info-title">Total saved</span>

            <span className="dashboard-window__info-text">
              {formatCurrency(totalSavedAmount || 0, user.currency, true)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
