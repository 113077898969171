import React, { ChangeEvent } from 'react';
import { ModalWindow } from '../../ModalWindow';

type Props = {
  emailToInvite: string;
  setEmailToInvite: (e: ChangeEvent<HTMLInputElement>) => void;
  isLoading: boolean;
  onClose: () => void;
  onAgree: () => void;
};

export const InviteModal: React.FC<Props> = ({ emailToInvite, setEmailToInvite, isLoading, onClose, onAgree }) => {
  return (
    <ModalWindow
      title="Invite new user to your organization"
      text={
        <>
          <div className="mb-16">
            Type an email address of the user you want to invite, the user will receive an email with your invitation.
            Please make sure that user don't have Partner-way account yet. If a user already exists, please contact us.
          </div>

          <input
            value={emailToInvite}
            onChange={setEmailToInvite}
            placeholder="Email of the user you want to invite"
            className="input w-100p mb-16"
            type="email"
          />

          <div className="text--bolt">Unaccepted invites are automatically deleted after 14 days.</div>
        </>
      }
      buttons={
        <>
          <button type="button" disabled={isLoading} className="button button--secondary" onClick={onClose}>
            Cancel
          </button>
          <button type="button" disabled={isLoading} className="button button--primary" onClick={onAgree}>
            {isLoading ? 'Wait' : 'Send Invite'}
          </button>
        </>
      }
    />
  );
};
