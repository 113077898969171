import React, { ChangeEvent, useState } from 'react';
import './../SingInWindow/SingInWindow.scss';
import googlePartner from '../../media/gc-partner-outline.svg';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { api } from '../../utils/fetchAPI';

type Props = {
  token: string;
  email: string;
  team: string;
};

export const TeamInviteSignUp: React.FC<Props> = ({ token, email, team }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<{
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string | undefined;
    role: string;
    password: string;
    confirmPassword: string;
    token: string;
  }>({
    firstName: '',
    lastName: '',
    email,
    phoneNumber: '',
    role: '',
    password: '',
    confirmPassword: '',
    token,
  });
  const [showPass, setShowPass] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const changeFormData = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const changePhoneNumber = (phoneNumber?: string) => {
    setFormData((prevState) => ({
      ...prevState,
      phoneNumber,
    }));
  };

  const acceptTeamInviteOnApi = async () => {
    if (passwordError || phoneError) {
      return;
    }

    setIsLoading(true);
    try {
      await api.post('/team-invites/accept', formData);

      navigate('/dashboard');
    } catch (err) {
      console.error(err);
      navigate('/sign-in');
    }
    setIsLoading(false);
  };

  return (
    <div className="sign-up-form-one mt-24 mb-40">
      <div className="sign-up-form-one__group w-400">
        <img width="150" src={googlePartner} alt="google-partner" className="sign-up-form-one__google-img" />

        <div className="sign-up-form-one__form-title">Let's Join the {team} Organization!</div>

        <div className="sign-up-form-one__form-subtitle">
          You'll be able to edit your personal information after creating the account.
        </div>

        <div className="sign-up-form-one__input-group mb-24">
          <input
            value={formData.firstName}
            onChange={changeFormData}
            name="firstName"
            type="text"
            className="input w-50p"
            placeholder="First name"
          />

          <input
            value={formData.lastName}
            onChange={changeFormData}
            name="lastName"
            type="text"
            className="input w-50p"
            placeholder="Last name"
          />
        </div>

        <div className="pos-rel">
          <PhoneInput
            onChange={changePhoneNumber}
            onBlur={() => {
              if (!formData.phoneNumber) {
                setPhoneError(true);

                return;
              }

              setPhoneError(false);
            }}
            value={formData.phoneNumber}
            name="phoneNumber"
            placeholder="Phone number"
            type="tel"
            className={classNames('phone-input-block mb-40 w-100p', {
              'input--error': phoneError,
            })}
            defaultCountry="UA"
            styleinput="tel"
          />

          {phoneError && (
            <span className="error-msg">
              <p className="input-sub-info input-sub-info--error">Phone number is invalid!</p>
            </span>
          )}
        </div>

        <div className="pos-rel">
          <input
            value={formData.role}
            onChange={changeFormData}
            name="role"
            type="text"
            className="input mb-24 w-100p"
            placeholder="Role"
          />
        </div>

        <div className="pos-rel">
          <input
            disabled
            value={formData.email}
            name="email"
            type="text"
            className="input mb-24 w-100p"
            placeholder="Email"
          />
        </div>

        <div className="sign-up-form-one__input-group mb-24 pos-rel">
          <input
            onBlur={() => {
              if (!formData.confirmPassword || formData.password !== formData.confirmPassword) {
                setPasswordError(true);

                return;
              }

              setPasswordError(false);
            }}
            onChange={changeFormData}
            value={formData.confirmPassword}
            type={showPass ? 'text' : 'password'}
            className={classNames('input w-50p', {
              'input--error': passwordError,
            })}
            name="confirmPassword"
            placeholder="Password"
          />

          <input
            onBlur={() => {
              if (formData.password !== formData.confirmPassword) {
                setPasswordError(true);

                return;
              }

              setPasswordError(false);
            }}
            onChange={changeFormData}
            value={formData.password}
            name="password"
            type={showPass ? 'text' : 'password'}
            className={classNames('input w-50p', {
              'input--error': passwordError,
            })}
            placeholder="Confirm password"
          />

          {passwordError && (
            <span className="error-msg">
              <p className="input-sub-info input-sub-info--error">Passwords do not match!</p>
            </span>
          )}
        </div>

        <div className="sign-up-form-one__checkbox-group">
          <input
            id="show-pass"
            onClick={() => setShowPass(!showPass)}
            type="checkbox"
            className="sign-up-form-one__checkbox"
          />
          <label htmlFor="show-pass" className="sign-up-form-one__checkbox-title">
            Show password
          </label>
        </div>

        <div className="sign-up-form-two__terms mb-40">
          By clicking <span className="text--bolt">Accept and Join</span>, you accept{' '}
          <a
            className="sign-up-form-two__terms sign-up-form-two__terms--link"
            href="https://admin.google.com/terms/workspace/1/4/en/premier_terms.html"
            target="_blank"
          >
            Agreement use of Google Workspace
          </a>
          &nbsp;and&nbsp;
          <a
            href="https://www.partner-way.com/terms"
            target="_blank"
            className="sign-up-form-two__terms sign-up-form-two__terms--link"
          >
            Privacy Policy
          </a>
        </div>

        <div className="sign-up-form-one__buttons-group">
          <Link to="/sign-in" className="button button--secondary">
            Sign In
          </Link>

          <button
            disabled={isLoading}
            onClick={() => acceptTeamInviteOnApi()}
            type="button"
            className="button button--primary"
          >
            Accept and Join {team.length > 10 ? team.slice(0, 9) + '...' : team}
          </button>
        </div>
      </div>
    </div>
  );
};
