import React from 'react';
import './ErrorMessage.scss';
import errIcon from '../../media/errorIcon.svg';

type Props = {
  text: null | string;
};

export const ErrorMessage: React.FC<Props> = ({ text }) => {
  const errorMessage = text || 'Unexpected Error';

  return (
    <div className="error-message">
      <div className="error-message__left">
        <img src={errIcon} alt="error" height="25" width="25" />
        <span className="error-message__text">{errorMessage}</span>
      </div>

      <div className="error-message__right"></div>
    </div>
  );
};
