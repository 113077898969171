import React, { ChangeEvent, useState } from 'react';
import { ModalWindow } from '../../ModalWindow';

type Props = {
  newPass: string;
  setNewPass: (e: ChangeEvent<HTMLInputElement>) => void;
  onClose: () => void;
  onAgree: () => void;
  isLoading: boolean;
};

export const NewPasswordModal: React.FC<Props> = ({ newPass, setNewPass, onClose, onAgree, isLoading }) => {
  const [showNewPassword, setShowNewPassword] = useState(false);

  return (
    <ModalWindow
      title="Change your Partner-way account password"
      text={
        <>
          <div className="mb-16">
            Enter a new password below to change your password.
            <br />
            If you dont have password, then you can set it from here.
          </div>

          <input
            value={newPass}
            onChange={setNewPass}
            placeholder="Type your new password"
            className="input w-100p mb-16"
            type={showNewPassword ? 'text' : 'password'}
          />

          <label style={{ display: 'flex', justifyContent: 'center', maxWidth: 'fit-content' }}>
            <input onClick={() => setShowNewPassword(!showNewPassword)} id="newpass" type="checkbox" />
            Show password
          </label>
        </>
      }
      buttons={
        <>
          <button type="button" disabled={isLoading} className="button button--secondary" onClick={onClose}>
            Cancel
          </button>
          <button type="button" disabled={isLoading} className="button button--primary" onClick={onAgree}>
            {isLoading ? 'Wait' : 'Change password'}
          </button>
        </>
      }
    />
  );
};
