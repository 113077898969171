import React, { ChangeEvent } from 'react';
import './SignUpFormPartTwo.scss';
import singUpImg2 from '../media/singUp-2.svg';
import googlePartner from '../media/gc-partner-outline.svg';
import { countriesList } from '../../../utils/countriesList';

type Props = {
  changePage: (page: number) => void;
  formData: any;
  changeFormData: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  formSubmit: () => void;
};

export const SignUpFormPartTwo: React.FC<Props> = ({ changePage, formData, changeFormData, formSubmit }) => {
  return (
    <div className="sign-up-form-two">
      <div className="sign-up-form-two__group w-400">
        <img width="150" src={googlePartner} alt="google-partner" className="sign-up-form-two__google-img" />

        <div className="sign-up-form-two__form-title">Company details</div>

        <div className="sign-up-form-two__form-subtitle">
          You'll be the Partnerway account admin since you're creating the account.
        </div>

        <input
          onChange={changeFormData}
          value={formData.domains}
          name="domains"
          placeholder="Company domain"
          type="text"
          className="input mb-24"
        />

        <div className="input--select">
          <select onChange={changeFormData} value={formData.region} className="input mb-24" name="region">
            <option value="" selected disabled hidden>
              Coutry / Region
            </option>

            {countriesList.map((country) => (
              <option value={country}>{country}</option>
            ))}
          </select>
        </div>

        <div className="input--select">
          <select onChange={changeFormData} value={formData.employees} className="input mb-24" name="employees">
            <option className="input--placeholder" value="" disabled hidden>
              Number of employees
            </option>
            <option value="1-10">1-10</option>
            <option value="11-20">11-20</option>
            <option value="21-50">21-50</option>
            <option value="51-250">51-250</option>
            <option value="251-500">251-500</option>
            <option value="501-5000">501-5000</option>
            <option value="5000+">5000+</option>
          </select>
        </div>

        <input
          onChange={changeFormData}
          value={formData.role}
          name="role"
          type="text"
          className="input mb-56"
          placeholder="Role"
        />

        <div className="sign-up-form-two__buttons-group">
          <button onClick={() => changePage(1)} type="button" className="button button--secondary">
            Back
          </button>

          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              formSubmit();
            }}
            className="button button--primary"
          >
            Create account
          </button>
        </div>
      </div>

      <div className="sign-up-form-two__group">
        <img width="260" height="315" src={singUpImg2} alt="google" />
      </div>
    </div>
  );
};
