import { Currency } from '../types/Currency';

const symbols: { [currency: string]: string } = {
  usd: '$',
  eur: '€',
  uah: '₴',
};

export const formatCurrency = (amount: string | number, currency: Currency, thousand = false) => {
  const numberAmount = Number(amount);
  const absAmount = thousand ? Math.abs(numberAmount) / 1000 : Math.abs(numberAmount);
  const negativeSymbol = numberAmount < 0 ? '-' : '';
  const currencySymbol = symbols[currency.toLowerCase()];
  const thousandSymbol = thousand ? 'k' : '';

  return [negativeSymbol, currencySymbol, absAmount.toFixed(2), thousandSymbol].join('');
};
