import React, { ChangeEvent, useState } from 'react';
import './SignUpFormPartOne.scss';
import singUpImg1 from '../media/singUp-1.svg';
import googlePartner from '../media/gc-partner-outline.svg';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { emailPattern } from '../../../utils/emailPattern';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

type Props = {
  changePage: (page: number) => void;
  formData: any;
  changeFormData: (e: ChangeEvent<HTMLInputElement>) => void;
  changePhoneNumber: (phoneNumber: string | undefined) => void;
};

export const SignUpFormPartOne: React.FC<Props> = ({ changePage, formData, changeFormData, changePhoneNumber }) => {
  const [showPass, setShowPass] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const validateEmail = () => {
    const { email } = formData;

    if (!email.trim() || !email.match(emailPattern)) {
      setEmailError(true);

      return;
    }

    setEmailError(false);
  };

  const validateForm = () => {
    const { email, password, phone, confirmPassword } = formData;

    if (!phone) {
      setPhoneError(true);

      return;
    }

    if (!email) {
      setEmailError(true);

      return;
    }

    if (!password || !confirmPassword) {
      setPasswordError(true);

      return;
    }

    if (passwordError || emailError) {
      return;
    }
    changePage(2);
  };

  return (
    <div className="sign-up-form-one">
      <div className="sign-up-form-one__group w-400">
        <img width="150" src={googlePartner} alt="google-partner" className="sign-up-form-one__google-img" />

        <div className="sign-up-form-one__form-title">Let's get started!</div>

        <div className="sign-up-form-one__form-subtitle">
          You'll be the Partnerway account admin since you're creating the account.
        </div>

        <div className="sign-up-form-one__input-group mb-24">
          <input
            value={formData.firstName}
            onChange={changeFormData}
            name="firstName"
            type="text"
            className="input w-50p"
            placeholder="First name"
          />

          <input
            value={formData.lastName}
            onChange={changeFormData}
            name="lastName"
            type="text"
            className="input w-50p"
            placeholder="Last name"
          />
        </div>

        <div className="pos-rel">
          <PhoneInput
            onChange={changePhoneNumber}
            onBlur={() => {
              if (!formData.phone) {
                setPhoneError(true);

                return;
              }

              setPhoneError(false);
            }}
            value={formData.phone}
            name="phone"
            placeholder="Phone number"
            type="tel"
            className={classNames('phone-input-block mb-40 w-100p', {
              'input--error': phoneError,
            })}
            defaultCountry="UA"
            styleinput="tel"
          />

          {phoneError && (
            <span className="error-msg">
              <p className="input-sub-info input-sub-info--error">Phone number is invalid!</p>
            </span>
          )}
        </div>

        <div className="pos-rel">
          <input
            onBlur={() => validateEmail()}
            onChange={changeFormData}
            value={formData.email}
            name="email"
            type="text"
            className={classNames('input mb-24 w-100p', {
              'input--error': emailError,
            })}
            placeholder="Email"
          />

          {emailError && (
            <span className="error-msg">
              <p className="input-sub-info input-sub-info--error">Email is invalid!</p>
            </span>
          )}
        </div>

        <div className="sign-up-form-one__input-group mb-24 pos-rel">
          <input
            onBlur={() => {
              if (!formData.confirmPassword || formData.password !== formData.confirmPassword) {
                setPasswordError(true);

                return;
              }

              setPasswordError(false);
            }}
            onChange={changeFormData}
            value={formData.confirmPassword}
            type={showPass ? 'text' : 'password'}
            className={classNames('input w-50p', {
              'input--error': passwordError,
            })}
            name="confirmPassword"
            placeholder="Password"
          />

          <input
            onBlur={() => {
              if (formData.password !== formData.confirmPassword) {
                setPasswordError(true);

                return;
              }

              setPasswordError(false);
            }}
            onChange={changeFormData}
            value={formData.password}
            name="password"
            type={showPass ? 'text' : 'password'}
            className={classNames('input w-50p', {
              'input--error': passwordError,
            })}
            placeholder="Confirm password"
          />

          {passwordError && (
            <span className="error-msg">
              <p className="input-sub-info input-sub-info--error">Passwords do not match!</p>
            </span>
          )}
        </div>

        <div className="sign-up-form-one__checkbox-group">
          <input
            id="show-pass"
            onClick={() => setShowPass(!showPass)}
            type="checkbox"
            className="sign-up-form-one__checkbox"
          />
          <label htmlFor="show-pass" className="sign-up-form-one__checkbox-title">
            Show password
          </label>
        </div>

        <div className="sign-up-form-two__terms mb-40">
          By clicking <span className="text--bolt">Accept and Continue</span>, you accept{' '}
          <a
            className="sign-up-form-two__terms sign-up-form-two__terms--link"
            href="https://admin.google.com/terms/workspace/1/4/en/premier_terms.html"
            target="_blank"
          >
            Agreement use of Google Workspace
          </a>
          &nbsp;and&nbsp;
          <a
            href="https://www.partner-way.com/terms"
            target="_blank"
            className="sign-up-form-two__terms sign-up-form-two__terms--link"
          >
            Privacy Policy
          </a>
        </div>

        <div className="sign-up-form-one__buttons-group">
          <Link to="/sign-in" className="button button--secondary">
            Sign In
          </Link>

          <button onClick={() => validateForm()} type="button" className="button button--primary">
            Accept and Continue
          </button>
        </div>
      </div>

      <div className="sign-up-form-one__group">
        <img width="260" height="315" src={singUpImg1} alt="google" />
      </div>
    </div>
  );
};
