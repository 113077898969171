import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { api } from '../../utils/fetchAPI';
import { Loader } from '../Loader';
import { ModalWindow } from '../ModalWindow';
import './YourDomainsWindow.scss';

export const YourDomainsWindow: React.FC = () => {
  const [domains, setDomains] = useState<{ domain: string }[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [domainToRemove, setDomainToRemove] = useState<null | string>(null);

  const getDoaminsFromAPI = async () => {
    setIsLoading(true);

    try {
      const res = await api.get<{ domain: string }[]>('/domains');

      setDomains(res);
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  };

  const removeDomainsSubscriptions = async () => {
    if (!domainToRemove) {
      return;
    }

    setIsLoading(true);

    try {
      await api.delete('/domains/' + domainToRemove);
    } catch (e) {
      console.error(e);
    }
    setDomainToRemove(null);
    getDoaminsFromAPI();
  };

  useEffect(() => {
    getDoaminsFromAPI();
  }, []);

  return (
    <div className="your-domains-window">
      {domainToRemove && (
        <ModalWindow
          title="Are you sure?"
          text="Please note if you remove your domain from reseller account you will lost all of your subscriptions."
          buttons={
            <>
              <button onClick={() => setDomainToRemove(null)} className="button button--primary">
                Close
              </button>
              <button onClick={() => removeDomainsSubscriptions()} className="button button--secondary">
                Remove
              </button>
            </>
          }
        />
      )}
      <h1 className="your-domains-window__title+ page__title">Your domains</h1>

      {isLoading && <Loader />}

      <div className="your-domains-window__grid">
        {domains?.map(({ domain }) => (
          <div key={domain} className="your-domains-window__domain-item">
            <div className="your-domains-window__status">
              <span
                className={classNames('status-tag', {
                  'status-tag--success': true,
                  // Doesnt available since we dont store info about connection status
                  //'status-tag--error': domain.status !== 'connected',
                })}
              >
                Connected
              </span>
            </div>
            <div className="your-domains-window__content">
              <span className="your-domains-window__domain-title">Domain</span>
              <span className="your-domains-window__domain-name">{domain}</span>

              {/* NOTE: This button delete domain from our DB and our reseller.*/}
              {/* It could be really helpful in development but NOT in production!!! */}
              {false && (
                <button className="button button--secondary" onClick={() => setDomainToRemove(domain)}>
                  Delete domain
                </button>
              )}
            </div>
          </div>
        ))}

        {!isLoading && (
          <Link to="/transfer-token" className="your-domains-window__domain-add">
            <div className="your-domains-window__add-plus">+</div>
            <div className="your-domains-window__add-text">Add domain</div>
          </Link>
        )}
      </div>
    </div>
  );
};
