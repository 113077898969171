import React from 'react';
import { SelectedSubscription } from '../../../types/SelectedSubscription';
import { ModalWindow } from '../../ModalWindow';

type Props = {
  subscription: SelectedSubscription;
  setSubscription: (subscription: SelectedSubscription | null) => void;
  setStep: (step: 0 | 1 | 2) => void;
};

export const AddSeatsModalOne: React.FC<Props> = ({ subscription, setSubscription, setStep }) => {
  const totalWithoutDiscount = subscription.cost * subscription.newSeats;
  const totalPriceWithDiscount = totalWithoutDiscount - totalWithoutDiscount * (subscription.discountPercent / 100);

  return (
    <ModalWindow
      title="Add seats"
      text={
        <>
          <div>Enter number of licenses you want to add:</div>
          <br />
          <input
            value={subscription.newSeats}
            onChange={(e) => {
              setSubscription({ ...subscription, newSeats: +e.target.value });
            }}
            type="number"
            className="input"
            placeholder="Number of seats"
          />
          <br />
          <div className="dashboard-window__modal-text-2">
            <strong>€{totalPriceWithDiscount.toFixed(2)}</strong> monthly cost for {subscription.newSeats} additonal
            licenses.
          </div>
        </>
      }
      buttons={
        <>
          <button className="button button--secondary" onClick={() => setSubscription(null)}>
            Cancel
          </button>
          <button className="button button--primary" onClick={() => setStep(2)}>
            Continue
          </button>
        </>
      }
    />
  );
};
