import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ErrorMessage } from '../../components/ErrorMessage';
import { Loader } from '../../components/Loader';
import { TeamInviteSignUp } from '../../components/TeamInviteSignUp';
import { BadRequestError, NotFoundError } from '../../utils/errors';
import { api } from '../../utils/fetchAPI';

type InviteData = {
  email: string;
  teamName: string;
};
export const AcceptTeamInvite: React.FC = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const [email, setEmail] = useState('');
  const [teamName, setTeamName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState<string | null>(null);

  const getInviteDataFromApi = async () => {
    setIsLoading(true);
    try {
      const inviteData = await api.get<InviteData>('/team-invites/' + token);

      setEmail(inviteData.email);
      setTeamName(inviteData.teamName);
    } catch (err) {
      console.error(err);
      if (err instanceof NotFoundError) {
        setIsError('Invitation Not Found!');
      } else if (err instanceof BadRequestError) {
        setIsError('Invitation Expired! Request a new one!');
      } else {
        setIsError('Unexpected error! Please try again later or contact us!');
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getInviteDataFromApi();
  }, []);

  const isShowForm = teamName && token && email && !isLoading && !isError;

  return (
    <div className="sing-up-page">
      {isLoading && (
        <div className="loader--center mt-24">
          <Loader />
        </div>
      )}

      {!isLoading && isError && <ErrorMessage text={isError} />}

      {isShowForm && <TeamInviteSignUp team={teamName} email={email} token={token} />}
    </div>
  );
};
