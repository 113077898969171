import { ForbiddenError, NotFoundError, UnauthorizedError, ConflictError, BadRequestError } from './errors';

type RequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

function request<T>(
  url: string,
  method: RequestMethod = 'GET',
  data: any = null,
  contentType: 'json' | 'formData' = 'json'
): Promise<T> {
  const options: RequestInit = { method };

  if (data && contentType === 'json') {
    options.body = JSON.stringify(data);
    options.headers = {
      'Content-Type': 'application/json; charset=UTF-8',
    };
    options.credentials = 'include';
  } else if (data && contentType === 'formData') {
    options.body = data;
  }

  return fetch('/api' + url, options).then((response) => {
    const { status } = response;

    if (status >= 400) {
      if (status === 400) {
        throw new BadRequestError(status.toString());
      } else if (status === 401) {
        throw new UnauthorizedError(status.toString());
      } else if (status === 403) {
        throw new ForbiddenError(status.toString());
      } else if (status === 404) {
        throw new NotFoundError(status.toString());
      } else if (status === 409) {
        throw new ConflictError(status.toString());
      } else {
        throw new Error(status.toString());
      }
    }

    if (status === 201 || status === 204) {
      return;
    }

    const contentType = response.headers.get('content-type');

    if (contentType && contentType.indexOf('application/json') !== -1) {
      return response.json();
    }

    return;
  });
}

export const api = {
  get: <T>(url: string) => request<T>(url),
  post: <T>(url: string, data: any) => request<T>(url, 'POST', data),
  put: <T>(url: string, data: any) => request<T>(url, 'PUT', data),
  delete: (url: string) => request(url, 'DELETE'),
  sendFiles: <T>(url: string, formData: FormData) => request<T>(url, 'POST', formData, 'formData'),
};
