import React from 'react';
import './InformationMessage.scss';
import successIcon from '../../media/succesIcon.svg';

type Props = {
  message: string;
};

export const InformationMessage: React.FC<Props> = ({ message }) => {
  return (
    <div className="success-message">
      <div className="success-message__left">
        <img src={successIcon} alt="success" height="25" width="25" />
        <span className="success-message__text">{message}</span>
      </div>

      <div className="success-message__right"></div>
    </div>
  );
};
