import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { subscriptionsSlice } from '../features/subscriptions/subscriptionsSlice';
import { userSlice } from '../features/user/userSlice';

export const store = configureStore({
  reducer: {
    subscriptions: subscriptionsSlice.reducer,
    user: userSlice.reducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
