import React, { useEffect, useState } from 'react';
import Payment, { MessageType, SdkMessage } from '@solidgate/react-sdk';
import { api } from '../../../utils/fetchAPI';
import { Loader } from '../../Loader';
import { form } from './solidgateFormParams';
import { ErrorMessage } from '../../ErrorMessage';

type Props = {
  isVatPayer: boolean;
  vatNumber: string;
};

export const SolidgatePaymentForm: React.FC<Props> = ({ isVatPayer, vatNumber }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isAddingCard, setIsAddingCard] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [paymentData, setPaymentData] = useState({
    merchant: '',
    signature: '',
    paymentIntent: '',
  });

  const showErrorMessage = (errMsg: string) => {
    setErrorMessage(errMsg);
    setTimeout(() => setErrorMessage(null), 10000);
  };

  const handleOnError = (e: SdkMessage[MessageType.Error]) => {
    const errMsg = Array.isArray(e.value.message) ? e.value.message[0] : e.value.message;

    showErrorMessage(errMsg || 'Unexpected error has occurred');
    setIsLoading(false);
    console.warn(e);
  };

  const handleOnFail = (e: SdkMessage[MessageType.Fail]) => {
    showErrorMessage(e.message || 'Unexpected error has occurred');
    setIsLoading(false);
    console.warn(e);
  };

  const handleOnMounted = (e: SdkMessage[MessageType.Mounted]) => {
    setIsLoading(false);
  };

  const handleOnSuccess = (e: SdkMessage[MessageType.Success]) => {
    // this handler works when user press the 'PAY' button
    console.log(e);
  };

  const handleOnSubmit = (e: SdkMessage[MessageType.Submit]) => {
    // this handler works when transaction processed by SolidGate
    console.log(e);
  };

  const handleOrderStatus = async (e: SdkMessage[MessageType.OrderStatus]) => {
    console.log(e);
    if (!e.response.transactions) {
      console.error('Error! No transactions found!');
      showErrorMessage('Error! No transactions found!');
      return;
    }

    const transactions = Object.keys(e.response.transactions);

    if (!transactions.length || !e.response.transactions[transactions[0]]) {
      console.error('Error! No transaction found!!');
      showErrorMessage('Error! No transaction found!!');
      return;
    }

    setIsAddingCard(true);

    const { card_token, brand, number, bank, card_holder } = e.response.transactions[transactions[0]].card;
    const customerData = {
      cardToken: card_token?.token,
      cardBrand: brand,
      cardNumber: number,
      cardBank: bank,
      cardHolder: card_holder,
    };

    try {
      await api.put('/me', { isVatPayer, vatNumber: vatNumber || null });
      await api.post('/cards', customerData);

      window.location.reload();
    } catch (e) {
      console.error(e);
    }

    setIsAddingCard(false);
  };

  const getPaymentDataFromAPI = async () => {
    setIsLoading(true);
    try {
      type MerchantData = {
        merchant: string;
        signature: string;
        paymentIntent: string;
      };
      const merchantData: MerchantData = await api.get('/cards/merchant-data');

      setPaymentData({
        merchant: merchantData.merchant,
        signature: merchantData.signature,
        paymentIntent: merchantData.paymentIntent,
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getPaymentDataFromAPI();
  }, []);

  return (
    <>
      {isAddingCard && (
        <div className="billing-loader-box">
          <Loader />
        </div>
      )}
      {paymentData.signature && (
        <Payment
          onError={handleOnError}
          onFail={handleOnFail}
          onMounted={handleOnMounted}
          onSubmit={handleOnSubmit}
          onSuccess={handleOnSuccess}
          onOrderStatus={handleOrderStatus}
          merchantData={paymentData}
          formParams={form.params}
          styles={form.styles}
        />
      )}

      {isLoading && (
        <div style={{ margin: '0 auto' }}>
          <Loader />
        </div>
      )}

      {errorMessage && <ErrorMessage text={errorMessage} />}
    </>
  );
};
