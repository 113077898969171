import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { Subscription } from '../../types/Subscription';
import { ForbiddenError, NotFoundError } from '../../utils/errors';
import { fetchSubscriptions } from './subscriptionsAPI';

export interface SubscriptionsState {
  allSubscriptions: Subscription[] | null;
  status: 'idle' | 'loading' | 'failed';
  errorMessage: any | null;
}

const initialState: SubscriptionsState = {
  allSubscriptions: null,
  status: 'idle',
  errorMessage: null,
};

export const getSubscriptionsAsync = createAsyncThunk<any, void, { state: RootState }>(
  'subscriptions/fetchSubscriptions',
  async (_, thunkAPI) => {
    const { subscriptions } = thunkAPI.getState();

    if (subscriptions.status !== 'loading') {
      return;
    }

    try {
      const subs = await fetchSubscriptions();
      return subs;
    } catch (err) {
      console.log(err);

      if (err instanceof ForbiddenError) {
        return thunkAPI.rejectWithValue('Error: Please contact us to edit your Reseller information!');
      } else if (err instanceof NotFoundError) {
        return thunkAPI.rejectWithValue('Your dont have any subscriptions yet!');
      }
      return thunkAPI.rejectWithValue('Unexpected error on loading your subscriptions!');
    }
  }
);

export const subscriptionsSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    setErrorMessage: (state, action: PayloadAction<string | null>) => {
      state.errorMessage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSubscriptionsAsync.pending, (state) => {
        if (state.status !== 'loading') {
          state.status = 'loading';
        }
      })
      .addCase(getSubscriptionsAsync.fulfilled, (state, action) => {
        state.status = 'idle';
        state.allSubscriptions = action.payload;
      })
      .addCase(getSubscriptionsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.payload;
      });
  },
});

export const { setErrorMessage } = subscriptionsSlice.actions;
