export const form = {
  params: {
    submitButtonText: 'Connect',
    isCardHolderVisible: true,
    hideCvvNumbers: true,
    googleFontLink: '//fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap',
    autoFocus: false,
  },
  styles: {
    form_body: {
      'font-family': 'Roboto',
    },
    'two-columns': {
      'grid-template-columns': '2fr 1fr',
      'column-gap': '24px',
      margin: '24px 0',
    },
    card_number: {
      'margin-top': '-0.5em',
    },
    submit_button: {
      'align-self': 'end',
      margin: '0 0 0 auto',
      'margin-top': '24px',
      'font-weight': 400,
      'font-size': '14px',
      'line-height': '16px',
      padding: '10px 16px',
      'font-family': 'inherit',
      border: 'none',
      outline: 'none',
      'max-width': 'max-content',
      height: 'max-content',
      transition: 'transform 0.2s',
      color: '#ffffff',
      background: '#3367d6',
      'border-radius': '4px',
      '.title-icon': {
        display: 'none',
      },
      ':disabled': {
        'background-color': '#404040',
        color: '#787878',
        cursor: 'not-allowed',
      },
      ':hover': {
        'background-color': '#2d59b6',
      },
      ':active': {
        'background-color': '#2d59a2',
      },
    },
    input_group: {
      '.label': {
        display: 'none',
      },
      '.brand-icon': {
        top: '0.8em',
      },
      '.tooltip-icon': {
        top: '0.8em',
      },
      '.not-empty > input': {
        padding: '12px 14px',
      },
      '.error-text': {
        position: 'absolute',
      },
      input: {
        'font-weight': 400,
        'font-size': '16px',
        'line-height': '24px',
        color: 'rgba(0, 0, 0, 1)',
        'background-color': 'white !important',
        padding: '12px 14px',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        'border-radius': '4px',
        height: 'max-content',
        '::placeholder': {
          'font-weight': 400,
          'font-size': '16px',
          'line-height': '24px',
          'letter-spacing': '0.0025em',
          color: 'rgba(0, 0, 0, 0.6)',
        },
        ':focus': {
          padding: '12px 14px',
        },
      },
    },
  },
};
