import React from 'react';
import './SideBar.scss';
import GCPartner from '../../media/gc-partner-outline.svg';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { ReactComponent as DashboardIcon } from './icons/dashboard.svg';
import { ReactComponent as BillingIcon } from './icons/billing.svg';
import { ReactComponent as DomainsIcon } from './icons/domains.svg';
import { ReactComponent as InfoIcon } from './icons/info.svg';
import { ReactComponent as SupportIcon } from './icons/support.svg';
import { useAppSelector } from '../../app/hooks';

type Props = {
  isInDevelopment?: boolean;
};

export const SideBar: React.FC<Props> = ({ isInDevelopment = false }) => {
  const { user } = useAppSelector((state) => state.user);

  const isPaymentType = user?.paymentType === 'card' || user?.paymentType === 'invoices';

  return (
    <div className="sidebar">
      <div className="sidebar__nav-list">
        {isPaymentType && (
          <NavLink
            to={isInDevelopment ? '/thank-you' : '/dashboard'}
            className={({ isActive }) =>
              classNames('sidebar__nav-link', {
                'sidebar__nav-link--is-active': isActive && !isInDevelopment,
              })
            }
          >
            <DashboardIcon className="sidebar__nav-link-icon" />
            Dashboard
          </NavLink>
        )}

        <NavLink
          to={isInDevelopment ? '/thank-you' : '/billing'}
          className={({ isActive }) =>
            classNames('sidebar__nav-link', {
              'sidebar__nav-link--is-active': isActive && !isInDevelopment,
            })
          }
        >
          <BillingIcon className="sidebar__nav-link-icon" />
          Billing
        </NavLink>

        {isPaymentType && (
          <>
            <NavLink
              to={isInDevelopment ? '/thank-you' : '/domains'}
              className={({ isActive }) =>
                classNames('sidebar__nav-link', {
                  'sidebar__nav-link--is-active': isActive && !isInDevelopment,
                })
              }
            >
              <DomainsIcon className="sidebar__nav-link-icon" />
              Your domains
            </NavLink>

            <NavLink
              to={isInDevelopment ? '/thank-you' : '/info'}
              className={({ isActive }) =>
                classNames('sidebar__nav-link', {
                  'sidebar__nav-link--is-active': isActive && !isInDevelopment,
                })
              }
            >
              <InfoIcon className="sidebar__nav-link-icon" />
              Information
            </NavLink>

            <NavLink
              to={isInDevelopment ? '/thank-you' : '/support'}
              className={({ isActive }) =>
                classNames('sidebar__nav-link', {
                  'sidebar__nav-link--is-active': isActive && !isInDevelopment,
                })
              }
            >
              <SupportIcon className="sidebar__nav-link-icon" />
              Support
            </NavLink>
          </>
        )}
      </div>

      <div>
        <img className="sidebar__partner-logo" src={GCPartner} alt="Google Cloud Partner" />
      </div>
    </div>
  );
};
