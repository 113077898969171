import React, { useEffect, useState } from 'react';
import './Header.scss';
import partnerwayLogo from '../../media/logo.svg';
import profileIcon from '../../media/header-profile-icon.svg';
import supportIcon from '../../media/header-support-icon.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { api } from '../../utils/fetchAPI';
import { APIUser } from '../../types/User';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getUserAsync, setUser } from '../../features/user/userSlice';

const notProtectedRoutes = ['/sign-in', '/sign-up', '/thank-you', '/page-not-found', '/invitation'];
const notShowHeaderRoutes = ['/sign-in', '/sign-up', '/page-not-found', '/invitation'];

export const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const { user, status } = useAppSelector((state) => state.user);

  const [isPopup, setIsPopUp] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const isNotProtectedRoute = notProtectedRoutes.includes(location.pathname);
  const isNotShowHeaderRoute = notShowHeaderRoutes.includes(location.pathname);

  const logoutUser = async () => {
    try {
      await api.get('/auth/logout');
      dispatch(setUser(null));
      navigate('/sign-in');
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    dispatch(getUserAsync());
    setIsPopUp(false);
  }, [location]);

  useEffect(() => {
    if (status === 'failed' && !isNotProtectedRoute) {
      navigate('/sign-in');
    }
  }, [status]);

  if (isNotProtectedRoute) {
    return null;
  }

  return (
    <div className={isNotShowHeaderRoute ? 'disp-none' : 'header'}>
      <div className="header__left-group">
        <img className="header__logo" alt="Partnerway Logo" src={partnerwayLogo} />
      </div>

      {!isNotProtectedRoute && (
        <div className="header__right-group">
          <Link to="/support" className="header__support">
            <img src={supportIcon} alt="support" />
          </Link>

          <span className="pos-rel" onMouseLeave={() => setIsPopUp(false)}>
            <span onMouseOver={() => setIsPopUp(true)} className="header__profile">
              {user && (
                <img
                  className="header__profile-img"
                  height="31"
                  width="31"
                  src={user.picture || profileIcon}
                  alt="profile"
                />
              )}
            </span>

            {isPopup && (
              <div className="header__pop-up">
                <Link to="/info" className="header__popup-link">
                  Settings
                </Link>

                <span onClick={() => logoutUser()} className="header__popup-link">
                  Log out
                </span>
              </div>
            )}
          </span>
        </div>
      )}
    </div>
  );
};
