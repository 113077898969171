import React, { useEffect, useState } from 'react';
import { TransferStep } from './TransferStep';
import './TransferTokenWindow.scss';
import copyIcon from '../../media/copyIcon.svg';
import icon1 from '../../media/stepNumber1.svg';
import icon2 from '../../media/stepNumber2.svg';
import icon3 from '../../media/stepNumber3.svg';
import icon4 from '../../media/stepNumber4.svg';
import icon5 from '../../media/stepNumber5.svg';
import step1 from '../../media/step1.png';
import step2 from '../../media/step2.png';
import step3 from '../../media/step3.png';
import step4 from '../../media/step4.png';
import googlePartner from '../../media/gc-partner-outline.svg';
import partnerwayLogo from '../../media/logo.svg';
import { api } from '../../utils/fetchAPI';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { APIUser } from '../../types/User';
import { InformationMessage } from '../InformationMessage';

const resellerCode = 'C028q7dbt';

export const TransferTokenWindow: React.FC = () => {
  const [token, setToken] = useState('');
  const [domain, setDomain] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isDomainError, setIsDomainError] = useState(false);
  const [isCopiedMessage, setIsCopiedMessage] = useState(false);

  const [user, setUser] = useState<APIUser | null>(null);

  const navigate = useNavigate();

  const validateDomain = () => {
    if (!domain.match(/^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/)) {
      setIsDomainError(true);
    } else {
      setIsDomainError(false);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(resellerCode);
    setIsCopiedMessage(true);
    setTimeout(() => setIsCopiedMessage(false), 3000);
  };

  const formSubmitHandler = async () => {
    validateDomain();

    if (!token.trim() || !domain.trim() || isDomainError) {
      return;
    }

    if (!user) {
      return;
    }

    setIsLoading(true);
    setIsError(false);

    try {
      if (!user.paymentType) {
        await api.put('/me', { token, domain });

        navigate('/billing');
      } else {
        await api.post('/domains', { token, domain });

        navigate('/domain');
      }
    } catch (err) {
      console.error(err);
      setIsError(true);
    }

    setIsLoading(false);
  };

  const getMeFromAPI = async () => {
    try {
      const res: APIUser = await api.get('/auth/me');

      setUser(res);
    } catch (err) {
      console.error(err);
      setIsError(true);
      setTimeout(() => setIsError(false), 10000);
    }
  };

  useEffect(() => {
    getMeFromAPI();
  }, []);

  return (
    <div className="transfer-token-window pos-rel">
      {isCopiedMessage && <InformationMessage message="Reseller Code copied to clipboard" />}

      <div className="transfer-token-window__header">
        <div className="transfer-token-window__title">Creating a Transfer Token account on Partnerway LLC</div>
        <div className="transfer-token-window__logos">
          <img src={partnerwayLogo} alt="logo" />
          <img src={googlePartner} alt="google" />
        </div>
      </div>

      {user && (
        <div className="transfer-token-window__content">
          <TransferStep
            text={
              <>
                Check the{' '}
                <a
                  href="https://support.google.com/a/answer/7643790?hl=en"
                  target="_blank"
                  className="transfer__pw-link"
                >
                  instruction by Google
                </a>
                .
                <br />
                Go to the page{' '}
                <a href="https://admin.google.com/TransferToken" target="_blank" className="transfer__pw-link">
                  Retrieve Transfer Token
                </a>{' '}
                using a{' '}
                <a href="https://admin.google.com" target="_blank" className="transfer__pw-link">
                  Google Administrator
                </a>{' '}
                account. In case the link directs you to Admin Workspace main page, but not to the Token Transfer page,
                try again with the anonymous tab.
              </>
            }
            icon={icon1}
            img={step1}
          />

          <TransferStep
            text={
              <>
                Find the Reseller ID entry form at the bottom of the page and enter.
                <br />
                Partnerway reseller code: <strong>{resellerCode + '  '}</strong>
                <button className="transfer__pw-code" onClick={() => copyToClipboard()}>
                  Copy code <img src={copyIcon} alt="copy" height="22" />
                </button>
                <br />
                Confirm the code by clicking the button below.
              </>
            }
            icon={icon2}
            img={step2}
          />

          <TransferStep text={<>Click "Generate transfer token".</>} icon={icon3} img={step3} />

          <TransferStep
            text={<>Having received the Transfer token, copy it and paste it in field below.</>}
            icon={icon4}
            img={null}
          />

          <div className="transfer-token-window__form">
            <input
              type="text"
              className={classNames('input', {
                'input--error': isError || isDomainError,
              })}
              placeholder="Domain"
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
              onBlur={() => validateDomain()}
            />

            <input
              type="text"
              className={classNames('input', {
                'input--error': isError,
              })}
              placeholder="Token"
              value={token}
              onChange={(e) => setToken(e.target.value)}
            />

            <div className="transfer-token-window__buttons">
              <button disabled={isLoading} onClick={formSubmitHandler} type="button" className="button button--primary">
                {isLoading ? 'Wait...' : 'Save'}
              </button>

              <Link className="sing-in-window__create-btn" to="/billing">
                Skip
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
