import React, { ChangeEventHandler, useEffect, useState } from 'react';
import './InformationWindow.scss';
import { countriesList } from '../../utils/countriesList';
import { APIUser } from '../../types/User';
import { api } from '../../utils/fetchAPI';
import { Loader } from '../Loader';
import { ErrorMessage } from '../ErrorMessage';
import { InformationMessage } from '../InformationMessage';
import { useAppSelector } from '../../app/hooks';
import { NewPasswordModal } from './NewPasswordModal';
import { InviteModal } from './InviteModal';

export const InformationWindow: React.FC = () => {
  const { user: userData } = useAppSelector((state) => state.user);

  const [user, setUser] = useState<APIUser | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState<string | null>(null);
  const [isInviteModal, setIsInviteModal] = useState(false);
  const [isNewPassModal, setIsNewPassModal] = useState(false);
  const [emailToInvite, setEmailToInvite] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const updateMe = async () => {
    if (!user) {
      return;
    }

    setIsLoading(true);

    try {
      await api.put('/me', user);

      setSuccess('Information saved!');
      setTimeout(() => setSuccess(null), 5000);
    } catch (e) {
      console.error(e);
      setError('Unexpected error on loading information!');
      setTimeout(() => setError(null), 4000);
    }

    setIsLoading(false);
  };

  const setNewPasswordOnApi = async () => {
    if (!user) {
      return;
    }

    if (!newPassword || newPassword.length < 5) {
      return;
    }

    setIsLoading(true);

    try {
      await api.put('/me', { password: newPassword });

      setSuccess('Your password changed!');
      setTimeout(() => setSuccess(null), 5000);
    } catch (e) {
      console.error(e);
      setError('Unexpected error on changing password!');
      setTimeout(() => setError(null), 4000);
    }

    setNewPassword('');
    setIsNewPassModal(false);
    setIsLoading(false);
  };

  const inviteNewUser = async () => {
    if (!emailToInvite) {
      return;
    }

    setIsLoading(true);

    try {
      await api.post('/team-invites', { email: emailToInvite });

      setIsInviteModal(false);
      setEmailToInvite('');
      setSuccess('Invitation sent!');
      setTimeout(() => setSuccess(null), 5000);
    } catch (e) {
      console.error(e);
      setError('Unexpected error on inviting user!');
      setTimeout(() => setError(null), 4000);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    setUser(userData);
  }, [userData]);

  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement> = (e) => {
    const { name, value } = e.target;
    setUser((prevState) => (prevState ? { ...prevState, [name]: value || null } : prevState));
  };

  return (
    <div className="information-window pos-rel">
      <div className="information-window__header">
        <h1 className="information-window__title page__title+">Information</h1>

        <div className="information-window__header-buttons">
          <button
            type="button"
            className="information-window__header-button button button--secondary"
            onClick={() => setIsNewPassModal(true)}
          >
            Change password
          </button>

          <button
            type="button"
            className="information-window__header-button button button--primary"
            onClick={() => setIsInviteModal(true)}
          >
            Invite New User
          </button>
        </div>
      </div>

      {isNewPassModal && (
        <NewPasswordModal
          newPass={newPassword}
          setNewPass={(e) => setNewPassword(e.target.value)}
          onClose={() => setIsNewPassModal(false)}
          onAgree={setNewPasswordOnApi}
          isLoading={isLoading}
        />
      )}

      {isInviteModal && (
        <InviteModal
          emailToInvite={emailToInvite}
          setEmailToInvite={(e) => setEmailToInvite(e.target.value)}
          isLoading={isLoading}
          onClose={() => setIsInviteModal(false)}
          onAgree={() => inviteNewUser()}
        />
      )}

      {error && <ErrorMessage text={error} />}

      {success && <InformationMessage message={success} />}

      {user ? (
        <div className="information-window__content">
          <div className="information-window__form">
            <div className="information-window__form-title">Organization Information</div>

            <input
              value={user.companyName || ''}
              onChange={handleChange}
              name="companyName"
              placeholder="Organization name"
              type="text"
              className="input information-window__input"
            />

            <input
              value={user.domain || ''}
              onChange={handleChange}
              name="domain"
              placeholder="Organization domain"
              type="text"
              className="input information-window__input"
            />

            <input
              value={user.companyNumber || ''}
              onChange={handleChange}
              name="companyNumber"
              placeholder="Company registration number/EDRPOU"
              type="text"
              className="input information-window__input"
            />

            <div className="input--select+ disp-none">
              <select value={user.region === 'Ukraine' ? 'uk' : 'en'} className="information-window__input input">
                <option value="" selected disabled hidden>
                  Language code
                </option>

                <option value="uk">UA</option>

                <option value="en">EN</option>
              </select>
            </div>

            <div className="information-window__form-title">Region Information</div>

            <div className="input--select">
              <select
                value={user.region || ''}
                onChange={handleChange}
                name="region"
                className="information-window__input input"
              >
                <option value="" selected disabled hidden>
                  Coutry / Region
                </option>

                {countriesList.map((country) => (
                  <option value={country} key={country}>
                    {country}
                  </option>
                ))}
              </select>
            </div>

            <input
              value={user.city || ''}
              onChange={handleChange}
              name="city"
              placeholder="City"
              type="text"
              className="input information-window__input"
            />

            <input
              value={user.address || ''}
              onChange={handleChange}
              name="address"
              placeholder="Street address"
              type="text"
              className="input information-window__input"
            />

            <input
              value={user.postalCode || ''}
              onChange={handleChange}
              name="postalCode"
              placeholder="Postal code"
              type="text"
              className="input information-window__input"
            />
          </div>

          <div className="information-window__form">
            <div className="information-window__form-title">Contact Information</div>
            <p className="information-window__form-subtitle">
              The name and email address are used to create the initial administrator account for Google Workspace, G
              Suite, Cloud Identity, and Chrome Enterprise Upgrade orders.{' '}
              <a href="https://support.google.com/a/answer/172176?hl=en" target="_blank" className="text--color-blue">
                Learn more
              </a>
              <br />
              <br />
              Changes to customer contact information will not be reflected in existing subscriptions.
            </p>
            <div className="input__group mb-24">
              <input
                value={user.firstName || ''}
                onChange={handleChange}
                name="firstName"
                className="input w-50p"
                type="text"
                placeholder="First name"
              />

              <input
                value={user.lastName || ''}
                onChange={handleChange}
                name="lastName"
                className="input w-50p"
                type="text"
                placeholder="Last name"
              />
            </div>

            <input
              disabled
              value={user.email}
              type="text"
              className="input information-window__input"
              placeholder="Email"
            />

            <input
              value={user.phoneNumber || ''}
              onChange={handleChange}
              name="phoneNumber"
              type="text"
              className="input information-window__input"
              placeholder="Phone number"
            />

            <div className="input__group+ disp-none mb-40">
              <span className="pos-rel w-50p">
                <input className="input w-100p" type="text" placeholder="Alternative email" />
                <span className="input-sub-info input-sub-info--absolute">
                  Аny other email that doesn't use the domain.{' '}
                  <a href="#lernmore" className="text--color-blue">
                    {' '}
                    Learn more
                  </a>
                </span>
              </span>

              <input
                value={user.phoneNumber || ''}
                onChange={handleChange}
                name="phoneNumber"
                className="input w-50p"
                type="text"
                placeholder="Phone"
              />
            </div>

            <div className="information-window__save-btn">
              <button type="button" disabled={isLoading} onClick={() => updateMe()} className="button button--primary">
                {isLoading ? 'Wait' : 'Save'}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};
