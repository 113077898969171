import React, { ChangeEvent, useRef, useState } from 'react';
import './SignUpWindow.scss';
import { SignUpFormPartOne } from './SignUpFormPartOne';
import { SignUpFormPartTwo } from './SignUpFormPartTwo';
import { api } from '../../utils/fetchAPI';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../Loader';
import { CSSTransition } from 'react-transition-group';
import { BadRequestError, ConflictError } from '../../utils/errors';
import { ErrorMessage } from '../ErrorMessage';

export const SignUpWindow: React.FC = () => {
  const navigate = useNavigate();
  const noderef = useRef(null);
  const noderef2 = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const [formPage, setFormPage] = useState(1);
  const [formData, setFormData] = useState<{
    firstName: string;
    lastName: string;
    phone: string | undefined;
    email: string;
    password: string;
    confirmPassword: string;
    domains: string;
    region: string;
    employees: string;
    role: string;
  }>({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    password: '',
    confirmPassword: '',
    domains: '',
    region: '',
    employees: '',
    role: '',
  });

  const changePageHandler = (page: number) => {
    setFormPage(page);
  };

  const changePhoneNumber = (phoneNumber?: string) => {
    setFormData((prevState) => ({
      ...prevState,
      phone: phoneNumber,
    }));
  };

  const changeFormData = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setFormData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const formSubmitHandler = async () => {
    setIsLoading(true);

    try {
      await api.post('/auth/sign-up', formData);

      navigate('/transfer-token');
    } catch (e) {
      console.error(e);
      if (e instanceof BadRequestError) {
        setErrorMsg('Provided data is invalid');
      } else if (e instanceof ConflictError) {
        setErrorMsg('User already exists!');
      } else {
        setErrorMsg('Unexpected error!');
      }

      setTimeout(() => setErrorMsg(''), 4000);
    }

    setIsLoading(false);
  };

  return (
    <div className="sign-up-window">
      <CSSTransition nodeRef={noderef} unmountOnExit in={formPage === 1} classNames="formTransitionCL-1" timeout={500}>
        <div ref={noderef}>
          <SignUpFormPartOne
            changePage={changePageHandler}
            formData={formData}
            changeFormData={changeFormData}
            changePhoneNumber={changePhoneNumber}
          />
        </div>
      </CSSTransition>

      <CSSTransition nodeRef={noderef2} unmountOnExit in={formPage === 2} classNames="formTransitionCL-2" timeout={500}>
        <div ref={noderef2}>
          <SignUpFormPartTwo
            changePage={changePageHandler}
            formData={formData}
            changeFormData={changeFormData}
            formSubmit={formSubmitHandler}
          />
        </div>
      </CSSTransition>

      {isLoading && (
        <div className="sign-up-loader">
          <Loader />
        </div>
      )}

      {errorMsg && <ErrorMessage text={errorMsg} />}
    </div>
  );
};
